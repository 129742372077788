@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes headerIn {
  from {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 100% 0, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

@keyframes headerIn {
  from {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 100% 0, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

@-webkit-keyframes title {
  from {
    right: 100%;
  }
  to {
    right: calc(50% - 3em);
  }
  
}

@keyframes title {
  from {
    right: 100%;
  }
  to {
    right: calc(50% - 3em);
  }
  
}

#header-bg {
  width: 100%;
  height: 50vh;
  background-image: url(/static/media/cool-background.e90c948d.svg);
  background-color: lightblue;
  background-size: cover;
  background-repeat: no-repeat;
  /*animation: headerIn 3s ease;*/
  position: relative;
}

#title {
  margin: 0px;
  position: absolute;
  bottom: 35%;
  right: calc(50% - 3em);
  font-size: 5em;
  color: aliceblue;
  white-space: nowrap;
  -webkit-animation: title 1s ease;
          animation: title 1s ease;
  font-family: 'Nunito', sans-serif;
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: white;
  overflow: hidden;
  font-family: 'Nunito', sans-serif;
  height: 3.5em;
  white-space: nowrap;
  /*box-shadow: 0px 1px 6px 1px grey;*/
}

.navcontent {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  min-width: 1000px;
  max-width: 1525px;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: black;
  text-align: center;
  padding: 18px 16px;
  margin: 0;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  color: blue;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: skyblue;
  color: white;
}

.topnav .logo {
    display: inline-block;
    vertical-align: top;
    width: 30px;
    height: 30px;
    margin-top: -5px;
    margin-left: -5px;
}

@-webkit-keyframes float {
  from {
    right: 100%;
  }
  to {
    right: 0%;
  }
  
}

@keyframes float {
  from {
    right: 100%;
  }
  to {
    right: 0%;
  }
  
}

.floating {
  min-height: 300px;
  width: 80%;
  max-width: 1525px;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  position: relative;
  min-width: 1000px;
  border-radius: 3px;
  z-index: 1;
  -webkit-animation: float 1s ease;
          animation: float 1s ease;
}

.titlename {
  padding-top: 20px;
  padding-left: 20px;
  font-family: 'Nunito', sans-serif;
  color:lightgray;
}

/* Solid border */
hr.solid {
  border-top: 1px solid aliceblue;
}

.footer {
  background-color: #E5E7E9;
  padding-top: 50px;
  padding-bottom: 30px;
  font-family: 'Nunito', sans-serif;
  margin-top: -10px;
  min-height: 100px;
}

.footercontent {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  min-width: 1000px;
  max-width: 1525px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

#footertext {
  font-size: 2em;
  margin: 0px;
}

#copyright {
  margin: 0px;
}

#links {
  float: right;
  font-size: 2em;
  color: black;
  margin-left: 5px;
  margin-right: 5px;
}

#links:hover {
  color: blue;
}

.About {
  display: -webkit-flex;
  display: flex;
}

#pic {
  margin: 2em;
  min-width: 350px;
}

.buzz {
  max-width: 500px;
}

#picture {
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  border-radius: 2px;
  box-shadow: -8px 9px 4px 1px rgb(0 0 0 / 7%);
  object-fit: cover;
}

#aboutme {
  margin: 2em;
  font-family: 'Nunito', sans-serif;
}

.Project {
  margin: 2em;
  font-family: 'Nunito', sans-serif;
}

.Contact {
  margin: 2em;
  font-family: 'Nunito', sans-serif;
}

.eduSkills {
  display: -webkit-flex;
  display: flex;
}

#uni {
  max-width: 350px;
  padding-left: 2em;
  padding-right: 2em;
}

.Skills {
  margin: 2em;
  font-family: 'Nunito', sans-serif;
}

.Education {
  margin: 2em;
  font-family: 'Nunito', sans-serif;
}

.allskills {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.allskills div{
  margin-right: 3em;
}

#skilltitle {
  margin-bottom: 0;
}

.Contact p, .Contact h1 {
  margin: 0px;
}

.contact-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

#mail {
  max-width: 20vw;
  min-width: 250px;
  padding-right: 10%;
}

#mail:hover {
  -webkit-filter: invert(7%) sepia(100%) saturate(7480%) hue-rotate(248deg) brightness(106%) contrast(141%);
          filter: invert(7%) sepia(100%) saturate(7480%) hue-rotate(248deg) brightness(106%) contrast(141%);
}

#projects ul, #projects p, #projects h2 {
  margin: 0px;
}

#projects ul {
  padding-top: 1em;
}

#projects {
  padding-bottom: 2em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.form {
  margin-top: 2em;
  padding-bottom: 2em;
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 5fr 1fr;
  grid-template-areas: 
  "a b"
  "c c"
  "d d";
  grid-gap: 1em;
  gap: 1em;
}

.form #name, .form #email, .form #subject {
  font-family: 'Nunito', sans-serif;
  border-radius: 4px;
  border: none;
  background-color: #f7f7f7;
  font-size: medium;
  padding-left: 1em;
}

#name {
  grid-area: a;
}

#email {
  grid-area: b;
}

#subject {
  grid-area: c;
  padding-top: 1em;
}

#submit {
  grid-area: d;
  display:inline-block;
  border:0.1em solid #f7f7f7;
  border-radius:0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-family: 'Nunito', sans-serif;
  font-size: medium;
  text-align:center;
  transition: all 0.2s;
  cursor: pointer;
}

#submit:hover {
  border:0.1em solid black;
  background-color:#FFFFFF;
}

#buttonbox {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.gitbutton {
  background-color:#f7f7f7;
  border:0.1em solid #f7f7f7;
  font-family: 'Nunito', sans-serif;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 2em;
  transition: all 0.2s;
  cursor: pointer;
}

.gitbutton:hover {
  border:0.1em solid black;
  background-color:#FFFFFF;
  font-weight: 700;
}

@media only screen and (min-width: 2000px) {
  #mail {
    max-width: 15vw;
  }
}

@media only screen and (max-width: 1000px) {
  .floating {
    width: 100%;
    min-width: auto;
  }
  .footercontent {
    width: 90%;
    min-width: auto;
    padding-left: 1em;
    padding-right: 1em;
  }
  .navcontent {
    width: 100%;
    min-width: auto;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  #title {
    font-size: 10vw;
  }
  #header-bg {
    height: 50vw;
    min-height: 325px;
  }
}

@media only screen and (max-width: 850px) {
  #projects {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }

  #mail {
    padding-right: 0;
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .About {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  #pic {
    margin-bottom: 0px;
    min-width: none;
  }
  
  #aboutme {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  #links {
    font-size: 3em;
  }

  .eduSkills {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media only screen and (max-width: 420px) {
  .topnav a {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
  .topnav a {
    padding-left: 6px;
    padding-right: 6px;
  }
}

